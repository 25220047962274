import type { Condo, CondoImage } from '@SHARED/core/entities/Condo';
import type { PresenterLabel } from '@SHARED/presenters/LabelPresenter';
import { PLACEHOLDER_IMAGE_URL } from '@SHARED/presenters/LabelPresenter';
import { grammarInflection } from '@SHARED/utils';

type CondoItemInformation =
	| 'floors'
	| 'unitsPerFloor'
	| 'socialElevators'
	| 'serviceElevators'
	| 'launchDate';

const labelsDictionary = {
	floors: {
		labels: ['Andar', 'Andares'],
		icon: null
	},
	unitsPerFloor: {
		labels: ['Apartamento por andar', 'Apartamentos por andar'],
		icon: null
	},
	socialElevators: {
		labels: ['Elevador social', 'Elevadores sociais'],
		icon: null
	},
	serviceElevators: {
		labels: ['Elevador de serviço', 'Elevadores de serviço'],
		icon: null
	},
	launchDate: {
		labels: ['Ano de construção'],
		icon: null
	}
};

export class CondoPresenter {
	static getThumbnail({ images }: Condo): CondoImage {
		const firstImage: CondoImage | undefined = images[0];

		const url = firstImage?.url || PLACEHOLDER_IMAGE_URL;

		const caption =
			firstImage?.caption ||
			(url === PLACEHOLDER_IMAGE_URL ? 'Imagem padrão' : null);

		return {
			url,
			caption,
			excludeFromWebsite: false
		};
	}

	static getAddressText({ region, city, state }: Condo): string {
		const cityAndState = [city, state].filter(Boolean).join(' - ');

		return `${region}, ${cityAndState}`;
	}

	static getStreetAddressText({ address, number }: Condo): string {
		return number ? `${address}, ${number}` : address;
	}

	static getItemsInformation(
		items: CondoItemInformation[],
		condo: Condo
	): PresenterLabel[] {
		const condoLabels: PresenterLabel[] = [];

		items.forEach(item => {
			const value = condo[item];

			if (!value || (typeof value === 'number' && value <= 0)) return;

			const { labels, icon } = labelsDictionary[item];

			const label =
				labels.length > 1
					? grammarInflection(Number(value), labels[0], labels[1])
					: labels[0];

			const formattedValue =
				item === 'launchDate' ? new Date(value).getFullYear() : value;

			condoLabels.push({ label, value: formattedValue, icon });
		});

		return condoLabels;
	}

	static getCondoKeywords(condo: Condo): string {
		const propertyLocation = `${condo.region} - ${condo.city}`;

		const propertyInfos = CondoPresenter.getItemsInformation(
			[
				'floors',
				'unitsPerFloor',
				'socialElevators',
				'serviceElevators',
				'launchDate'
			],
			condo
		);

		const propertyInfoKeywords = propertyInfos.map(
			({ label, value }) =>
				`condomínio com ${value} ${label.toLowerCase()} em ${propertyLocation}`
		);

		const featureKeywords = condo.featuresByCategory
			? Object.values(condo.featuresByCategory)
					.map(featureCategory =>
						featureCategory.map(({ name: featureName }) => [
							`condomínio com ${featureName} em ${propertyLocation}`,
							`condomínio com ${featureName}`
						])
					)
					.flat(2)
			: [];

		const keywords = [
			`condomínio de luxo em ${propertyLocation}`,
			`condomínio à venda em ${propertyLocation}`,
			...propertyInfoKeywords,
			...featureKeywords
		];

		return keywords.join(',');
	}
}
